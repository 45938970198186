const aws = {
  region: 'eu-west-1',
  identityPoolId: 'eu-west-1:5229d8d7-cefb-4d11-953e-7be0bbba3cea',
  userPoolId: 'eu-west-1_PPyp1sGNX',
  userPoolClientId: '7ffjaj337nc3jmida7p7poi671',
  authDomain: 'auth.mes.condoor.com',
  mesDomain: 'mes.condoor.com',
  userManagementAppsyncEndpoint: 'https://auth.mes.condoor.com/graphql',
};
export default aws;
